import React from 'react'
import linkedinIcon from '../images/tech_icons/linkedin.png'
import facebookIcon from '../images/tech_icons/facebook.png'
import whatsappIcon from '../images/tech_icons/whatsapp.png'
import githubIcon from '../images/tech_icons/github.png'
import email from '../images/tech_icons/email.png'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const ContactCard = () => {

  const copyEmail = () =>{
    navigator.clipboard.writeText('devwhizards@gmail.com')
    toast.success("Email Copied");
  }
  const copyPhone = () =>{
    navigator.clipboard.writeText("+44 7825 681634")
    toast.success("Phone Copied");
  }

  return (
    <div id='contact' className="horizontal-card-container" style={{padding: '40px 0px'}}>
        <div className='contact-card-left'>
            <div className='contact-card-left-content'>
                <h2>Drop us a line or say 👋 on social networks</h2>
                <p>Whether you're a business or startup seeking to transform your ideas into exceptional mobile and web applications, We are here to collaborate and bring your vision to life. Feel free to reach out, and let's turn your ideas into reality! 💻📱</p>
            </div>
        </div>
        
        <div className='contact-card-right'>
            <a href='https://www.linkedin.com' target='_blank' className='brand-logo'><img src={linkedinIcon} className='contact-icon' alt='linkedin'/> </a>
             <a href='https://www.facebook.com' target='_blank' className='brand-logo'><img src={facebookIcon} className='contact-icon' alt='facebook'/> </a>
             <a href='https://www.github.com/Syed-Haider-Ali' target='_blank' className='brand-logo'><img src={githubIcon} className='contact-icon' alt='github'/> </a>
              <a href='#contact' className='brand-logo' title="+44 7825 681634" onClick={copyPhone}><img src={whatsappIcon} className='contact-icon' alt='whatsapp'/> </a>
               <a href='#contact' className='brand-logo' title='devwhizards@gmail.com' onClick={copyEmail}><img src={email} className='contact-icon' alt='email'/> </a>  
               <ToastContainer />
        
        </div>
    </div>
  )
}

export default ContactCard

{/* <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
  Tooltip on top
</button> */}