import lcl from '../images/LCL.png'
import paysa from '../images/Paysa.png'
import mangtum from '../images/Mangtum.png'
import dadBobSculptors from '../images/Project_Images/dad_bob_sculptors_comp.png'
import dealFlipping from '../images/Project_Images/deal_flipping_comp.png'
import e2MasterSheet from '../images/Project_Images/e2_master_sheet_comp.png'
import graphics from '../images/Project_Images/graphics_force_comt.png'
import goHighLevel from '../images/Project_Images/go_high_level_comp.png'
import awkwardSEO from '../images/Project_Images/awkward_seo.PNG'





const projects = [
    {
        name: 'LCL Digitalization',
        stack: ['Python', 'Django', 'React.js', 'Redux' ,'React Native', 'ML' ,'Sagemaker' ,'EC2', 'S3 Bucket', 'Jenkins'],
        description: 'LCL , driven by an ERP system, revolutionized data management by centralizing user information through diverse methods like',
        image: lcl,
        url: "www.luckycement.biz"
    },
    {
        name: 'Paysa',
        stack: ['Next.js', 'Three.js' , 'Redux', '3d Modelling' , 'S3 Bucket', 'Jenkins'],
        description: 'PaySa, a rapidly emerging player in the digital payments market, Offering innovative solutions, quick settlement services, data-',
        image: paysa,
        url: "https://www.paysa.com.pk/"
    },
    {
        name: 'Mangtum',
        stack: ['Python','Django', 'React.js', 'React Native' ,'Redux', 'EC2' , 'S3 Bucket'],
        description: 'Mangtum.in is an expansive global marketplace connecting buyers and sellers worldwide, facilitating the exchange of a wide',
        image: mangtum,
        url: "https://mangtum.com/"
    },
    {
        name: 'Dad Bob Sculptors',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: 'It is a Fitness Web Application helping Indivisuals to improve their Physical and Mental Fitness',
        image: dadBobSculptors,
        url: "https://https://www.dadbodsculptors.com"
    },
    {
        name: 'Awkward Media SEO Blog',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: 'It is a blog website stating Best SEO methods to rank website on 1st page for free',
        image: awkwardSEO,
        url: "https://optin.awkward-media.com/free-seo-call"
    },
    {
        name: 'E2A Business Automation',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: 'It is a subscription website giving best End-to-Anywhere Business ',
        image: e2MasterSheet,
        url: "https://optin.awkward-media.com/free-seo-call"
    },
    {
        name: 'Real Estate Management System',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: `Go High Level is a small family owned, solution based real estate company deep within the heart of NJ. We buy houses in any condition all throughout NJ. Our team is here to provide homeowners with a no hassle, quick closing and fast cash solution.,  `,
        image: goHighLevel,
        url: "https://optin.awkward-media.com/free-seo-call"
    },
    {
        name: 'Content Design Academy',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: `Graphics For Coaches Presents CONTENT DESIGN ACADEMY the ultimate template goldmine Perfect for any health and fitness professional looking to generate leads and sales online, whether you have 100 or 10,000 followers.`,
        image: graphics,
        url: "https://graphicsforcoaches.co.uk/content-design-academy"
    },
    {
        name: 'Deal Flipping ',
        stack: ['Click Funnel', 'Digital Marketing', 'SEO'],
        description: 'It is a Business e-book library, Clients can purchase books in pdf or they can avail online book reading',
        image: dealFlipping,
        url: "https://www.dealflipping.com/wifi-method-ebook"
    },
]

export default projects